<template>
  <div class="survey">
    <div class="survey__top mb-16 mb-tablet-48">
      <router-link to="/">
        <base-logo-vertical class="mobile" />
        <base-logo-horizontal class="tablet-desktop" />
      </router-link>
    </div>

    <div class="survey__header mb-8 mb-tablet-12">
      <h1 class="survey__title heading heading--medium color-primary mb-2">
        Formularz konfiguracyjny
      </h1>
      <ul class="survey__steps mb-16 mb-tablet-0">
        <li class="survey__steps-item mobile">Wypełnij ankietę</li>
        <li class="survey__steps-item survey__done" :class="{ active: done }">
          Gotowe!
        </li>
      </ul>

      <img class="mobile" src="./icon-questionaire.svg" alt="" />
    </div>

    <div v-if="done" class="mb-32 mb-tablet-64">
      <p class="mb-16 done__paragraph">
        Otrzymaliśmy Twoją ankietę. Niebawem się z Tobą skontaktujemy.
      </p>

      <div class="done__buttons mb-32 mb-tablet-64">
        <base-button
          tag="router-link"
          to="/app"
          class="done__button mb-4 mb-tablet-0"
          uppercase
          >Serwis</base-button
        >
        <base-button
          tag="router-link"
          to="/app/account/#modules"
          theme="secondary"
          class="done__button mb-4 mb-tablet-0"
          uppercase
          plain
          >Moje moduły</base-button
        >
        <base-button
          @click="reset"
          theme="secondary"
          class="done__button mb-4 mb-tablet-0"
          uppercase
          plain
          >Nowa konfiguracja</base-button
        >
      </div>
    </div>

    <div v-else class="row row--between mb-48 mb-tablet-64">
      <div class="col-12 col-tablet-7 col-desktop-8">
        <div class="survey__intro">
          <p v-if="$route.query.fromSignup" class="tablet-desktop">
            W trakcie zakładania konta poprosiłeś o niezależną konfigurację.
          </p>

          <p class="mobile-bold">
            Wypełnij proszę formularz, aby monitoring jak najlepiej odpowiadał
            Twoim preferencjom i potrzebom Twojej praktyki.
          </p>

          <p class="mb-tablet-32">
            Jeśli zależy Ci na odseparowaniu informacji z kilku dziedzin,
            objęciu ich odrębnym monitoringiem lub raportami – wypełnij kilka
            ankiet.
          </p>

          <p class="survey__mobile-notice mobile mb-16">
            Aby monitoring objął dokładnie taki zakres legislacji, którego
            oczekujesz - ważna jest precyzja informacji. Rozważ wypełenienie
            ankiety na komputerze.
          </p>
        </div>

        <survey-form
          @submit="send"
          :preview="isAccepted"
          :surveyPreview="surveyPreview"
        >
          <portal-target name="contact" />
        </survey-form>
      </div>

      <div class="survey__contact col-12 col-tablet-5 col-desktop-4">
        <portal
          to="contact"
          :disabled="$util.mq('tablet')"
          style="height: 100%"
        >
          <contact-box
            :open="contactRequested"
            @toggle="contactRequested = !contactRequested"
          >
            <base-input
              v-if="!userPhone"
              :disabled="preview"
              v-model="phone"
              class="mb-4"
            >
              twój numer telefonu
            </base-input>
          </contact-box>
        </portal>
      </div>
    </div>

    <page-footer />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PageFooter from '@/components/PageFooter/PageFooter';
import SurveyForm from './components/SurveyForm';
import ContactBox from './components/ContactBox';

export default {
  components: {
    PageFooter,
    SurveyForm,
    ContactBox,
  },
  metaInfo() {
    return {
      title: 'Formularz konfiguracyjny',
    };
  },
  data() {
    return {
      phone: '',
      done: false,
      contactRequested: false,
      preview: this.$route.params.id !== undefined,
      surveyPreview: null,
    };
  },
  computed: {
    ...mapState({
      userPhone: state => state.user.phone_number,
    }),
    isAccepted() {
      return this.surveyPreview?.accepted;
    },
  },
  created() {
    if (this.$route.params.id) {
      this.fetchSurvey();
    }
  },
  methods: {
    async fetchSurvey() {
      const { data } = await this.$api.get(
        `/v1/my_polls/${this.$route.params.id}/`,
        {
          cache: {
            ignoreCache: true,
          },
        }
      );
      this.contactRequested = data.help_request;
      this.surveyPreview = data;
    },
    async send(data) {
      if (!this.userPhone) {
        this.$store.dispatch('user/updateAccount', {
          phone_number: this.phone,
        });
      }
      const payload = {
        ...data,
        help_request: this.contactRequested,
      };
      if (this.$route.params.id) {
        await this.$api.put(`v1/my_polls/${this.$route.params.id}/`, payload);
      } else {
        await this.$api.post(`v1/my_polls/`, payload);
      }
      this.done = true;
    },
    reset() {
      this.done = false;
      this.contactRequested = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Survey';
</style>

<template>
  <div
    class="field mb-16"
    :class="{
      'field--active': focused,
      'field--has-value': hasValue,
    }"
  >
    <div class="number mb-4 mobile">{{ number }}/11</div>

    <label class="label mb-4">
      <slot name="label" />
    </label>

    <div class="input-wrap">
      <div class="number tablet-desktop">
        <span class="number__current">{{ number }}</span>
        <span class="number__total">/11</span>
      </div>

      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    number: Number,
    focused: Boolean,
    hasValue: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.field {
  position: relative;

  @include mq('tablet') {
    padding-left: 7rem;
  }
}
.input-wrap {
  position: relative;
}
.number {
  font-size: 1.14rem;
  font-weight: bold;

  @include mq('tablet') {
    position: absolute;
    top: 0;
    left: -7rem;
    font-size: 2.5rem;
    color: $gray-5;
  }
}
.number__current {
  transition: color 0.2s ease;
}
.number__total {
  opacity: 0;
  transition: opacity 0.1s ease;
}
.label {
  display: block;
}
.field--active {
  z-index: 1000;

  .number__current {
    color: $primary;
  }
  .number__total {
    opacity: 1;
  }
}
.field--has-value {
  .number__current {
    color: $primary;
  }
}
</style>

<template>
  <div class="contact-box-wrap mb-16 mb-tablet-0">
    <div class="contact-box">
      <div class="contact-box__header">
        <p class="contact-box__header-text">
          <strong>Potrzebujesz pomocy w&nbsp;uzupełnieniu ankiety?</strong>
        </p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          viewBox="0 0 36 36"
          class="contact-box__header-icon mb-4 mb-tablet-12"
        >
          <path
            fill="#0042FF"
            fill-rule="nonzero"
            d="M34 25c-2.5 0-4.9-.4-7.14-1.14-.7-.22-1.48-.06-2.04.48l-4.4 4.4A30.09 30.09 0 0 1 7.24 15.56l4.4-4.42c.56-.52.72-1.3.5-2A22.72 22.72 0 0 1 11 2c0-1.1-.9-2-2-2H2C.9 0 0 .9 0 2c0 18.78 15.22 34 34 34 1.1 0 2-.9 2-2v-7c0-1.1-.9-2-2-2zm-2-7h4c0-9.94-8.06-18-18-18v4c7.74 0 14 6.26 14 14zm-8 0h4c0-5.52-4.48-10-10-10v4c3.32 0 6 2.68 6 6z"
          />
        </svg>
      </div>

      <div class="contact-box__step">
        <span class="arrow arrow--blue mb-4"></span>
        <p>
          wybierz opcję kontaktu telefonicznego
        </p>
      </div>
      <base-button
        @click="$emit('toggle')"
        :theme="open ? 'primary' : 'gray-3'"
        plain
        full
        :class="{ 'mb-4 mb-tablet-12': open }"
      >
        Proszę o kontakt
      </base-button>

      <div v-if="open">
        <slot />
        <div class="contact-box__step">
          <span class="arrow mb-4"></span>
          <p>
            dokończ uzupełnianie ankiety, na tyle ile jest to możliwe
          </p>
        </div>
        <div class="contact-box__step">
          <span class="arrow mb-4"></span>
          <p>
            oddzwonimy do Ciebie niezwłocznie po otrzymaniu zgłoszenia
          </p>
        </div>
        <div class="contact-box__step">
          <span class="arrow mb-4"></span>
          <p>
            pamiętaj, że możesz zostawić niewypełnioną ankietę lub wypełnić jej
            część w celu ułatwienia rozmowy
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    open: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.contact-box-wrap {
  @include mq('tablet') {
    min-height: 100vh;
    top: 0;
    position: sticky;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.contact-box {
  border-radius: 1.4rem;
  font-size: 1rem;

  @include mq('tablet') {
    padding: 2rem;
    text-align: center;
    box-shadow: 5px 5px 30px -10px $gray-3;
  }
  @include mq('desktop') {
    padding: 3rem;
  }

  &__arrow-blue {
    transform: rotate(90deg);
    margin: 1rem 0;
  }
  &__arrow-gray {
    transform: rotate(-90deg);
    margin: 1rem 0;
  }
}
.contact-box__header {
  max-width: 15rem;
  display: flex;

  @include mq('tablet') {
    max-width: 100%;
    flex-direction: column-reverse;
    align-items: center;
  }
}
.contact-box__header-text {
  margin-right: 1rem;
}
.contact-box__header-icon {
  width: 3rem;

  @include mq('tablet') {
    width: 2.5rem;
  }
}
.contact-box__step {
  display: flex;

  @include mq('tablet') {
    display: block;
  }
}
.arrow {
  display: block;
  margin-right: 1rem;
  margin-top: -0.3rem;

  @include mq('tablet') {
    margin-right: 0;
    margin-top: 0;
  }

  &::before {
    @include arrowPseudo($gray-2, 'down');
    display: inline-block;
  }
}
.arrow--blue::before {
  @include arrowPseudo($primary, 'down');
}
</style>

<template>
  <base-spinner v-if="loading || (preview && !surveyPreview)" size="large" />
  <div v-else class="survey-form">
    <form-field
      v-for="(field, index) in fields"
      :key="field.name"
      :number="index + 1"
      :focused="field.focused"
      :hasValue="field.value.length > 0"
    >
      <template #label>{{ field.label }}</template>
      <base-textarea
        v-if="field.type === 'textarea'"
        v-model="field.value"
        :placeholder="field.placeholder"
        :rows="8"
        @focus="field.focused = true"
        @blur="field.focused = false"
        :disabled="preview"
        autocomplete="false"
      />
      <base-input-plain
        v-else-if="field.type === 'text'"
        v-model="field.value"
        @focus="field.focused = true"
        @blur="field.focused = false"
        :disabled="preview"
        autocomplete="false"
      />
      <base-multiselect-plain
        v-else-if="field.type === 'select'"
        v-model="field.value"
        :name="field.name"
        :options="field.options"
        @focus="field.focused = true"
        @blur="field.focused = false"
        :disabled="preview"
      />
    </form-field>

    <slot />

    <div class="text-right" v-if="!preview">
      <base-button
        :loading="sending"
        @click="submit"
        type="submit"
        theme="secondary"
        uppercase
        wide
        class="survey-form__submit"
      >
        Wyślij
      </base-button>
    </div>
  </div>
</template>

<script>
import FormField from './FormField';

export default {
  components: {
    FormField,
  },
  props: {
    preview: Boolean,
    surveyPreview: Object,
  },
  data() {
    return {
      loading: false,
      sending: false,
      fields: [
        {
          name: 'amends_or_derogates',
          type: 'textarea',
          label: `Jeśli chcesz, żeby monitoring wychwycił każdą zmianę
            określonych ustaw lub projekt który będzie zapowiadał
            ich derogację`,
          placeholder: 'Wskaż ich tytuły...',
          value: '',
          focused: false,
        },
        {
          name: 'eu_related_acts',
          type: 'text',
          label: `Jeśli chcesz, żeby monitoring wykrył każdą ustawę,
            która będzie powoływała się na określone akty prawa
            europejskiego –  wskaż tu tytuł odpowiednich dyrektyw,
            rozporządzeń itd.`,
          value: '',
          focused: false,
        },
        {
          name: 'minister',
          type: 'select',
          label: `Jeśli interesują Cię projekty konkretnych
            ministrów – wskaż ich`,
          value: [],
          options: [],
          focused: false,
        },
        {
          name: 'gov_branch',
          type: 'select',
          label: `Możesz też dookreślić śledzone projekty pod
            względem działu administracji rządowej`,
          value: [],
          options: [],
          focused: false,
        },
        {
          name: 'working_tops',
          type: 'select',
          label: `Rządowe Centrum Legislacji posługuje się tagami,
            które dookreślają przedmiot regulacji. Możesz je przejrzeć
            i wybrać niektóre z nich, jeśli pomoże Ci to dookreślić
            swoje potrzeby`,
          value: [],
          options: [],
          focused: false,
        },
        {
          name: 'keywords',
          type: 'text',
          label: `Wskaż słowa, których wystąpienie w projekcie
            będzie kwalifikować go do monitoringu`,
          value: '',
          focused: false,
        },
        {
          name: 'regulations',
          type: 'text',
          label: `W przypadku rozporządzeń monitoring uwzględni akty
            wykonawcze do wskazanych wyżej ustaw oraz wykorzysta
            pozostałe wskazane wyżej kryteria. Napisz, jeśli chcesz
            wykorzystać inne`,
          value: '',
          focused: false,
        },
        {
          name: 'practice',
          type: 'text',
          label: `Na specjalne zamówienie vigilex może agregować
            też dokumenty z praktyki regulatorów, organów centralnych,
            ministerstw itp.`,
          value: '',
          focused: false,
        },
        {
          name: 'other_criteria',
          type: 'text',
          label: `Jeśli zależałoby Ci na uwzględnieniu innych,
            niewskazanych powyżej kryteriów – napisz o tym tutaj.
            monitoring może np. wybierać określone
            dokumenty z konsultacji itp.`,
          value: '',
          focused: false,
        },
        {
          name: 'frequency',
          type: 'text',
          label: `Jeśli chciałbyś otrzymywać alerty po zajściu
            określonych zdarzeń dotyczących wybranych projektów to
            jakie miałyby to być wydarzenia?`,
          value: '',
          focused: false,
        },
        {
          name: 'free_speech',
          type: 'text',
          label: `Jeśli powyższy formularz nie uwzględnia
            jakichkolwiek potrzeb lub oczekiwań zwiazanych
            z monitoringiem - napisz o tym tutaj`,
          value: '',
          focused: false,
        },
      ],
    };
  },
  watch: {
    surveyPreview() {
      this.fields.map(field => {
        if (this.surveyPreview[field.name]) {
          field.value = this.surveyPreview[field.name];
        }
      });
    },
  },
  created() {
    if (!this.preview) {
      this.fetchData();
    }
  },
  methods: {
    async fetchData() {
      this.loading = true;
      const { data } = await this.$api.get('v1/poll_choices/');
      this.loading = false;
      const {
        minister_choices,
        gov_branch_choices,
        working_tops_choices,
      } = data.results[0].choices;
      this.fields[2].options = minister_choices;
      this.fields[3].options = gov_branch_choices;
      this.fields[4].options = working_tops_choices;
    },
    submit() {
      const values = Object.fromEntries(
        this.fields.map(field => [field.name, field.value])
      );
      this.sending = true;
      this.$emit('submit', values);
    },
  },
};
</script>

<style lang="scss" scoped>
.survey-form {
  font-size: 1.14rem;
}
.survey-form__submit {
  width: 100%;

  @include mq('tablet') {
    width: auto;
  }
}
</style>
